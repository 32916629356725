import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import {Alert, Button, Col, Container, Row} from "reactstrap";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { userService } from "services/user.service";
import { copyTextToClipboard } from "utils/helper";
import ChangePassword from "views/ChangePassword";
import { initializeApp } from "https://www.gstatic.com/firebasejs/9.1.3/firebase-app.js";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "https://www.gstatic.com/firebasejs/9.1.3/firebase-auth.js";
import 'firebase/auth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import games from "../../assets/img/game.jpg";
import {firebaseAuthDomain, firebaseMessagingSenderId} from "utils/constants";
import { firebaseApiKey } from "utils/constants";
import { firebaseProjectId } from "utils/constants";
import { firebaseStorageBucket } from "utils/constants";
import { firebaseMeasurementId } from "utils/constants";
import { firebaseAppId } from "utils/constants";


const MyProfile = () => {

    const [userData, setUserData] = useState(userService.getLocalStorageData());
    //profile form
    const [showChangePwd, setShowChangePwd] = useState(false);
    const [lastName, setLastName] = useState(userData.last_name);
    const [showSave, setShowSave] = useState(false);
    const [isUploading, setisUploading] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(userData.profile_img);
    const [profileFile, setProfileFile] = useState(null);
    const [firstName, setFirstName] = useState(userData.first_name);

   //upi fields
    const [upiId, setUpiId] = useState(userData.user_upi_id);
    const [instaHandle, setInstaHandle] = useState(userData.insta_handle);
    const [instaHandleSet, setInstaHandleSet] = useState( userData.insta_handle && userData.insta_handle.length > 0 ? 1 : 0);
    const [showUPIError, setShowUPIError] = useState(false);
    const [upiError, setUpiError] = useState("");
    const [upiIdSet, setUpiIdSet] = useState( userData.user_upi_id?1:0);
    //mobile fields
    const [numberVerified, setNumberVerified] = useState(userData.mobile_number?1:0);
    const [canGenerateOtp, setCanGenerateOtp] = useState(false);
    const [showMobileError, setShowMobileError] = useState(false);
    const [mobileError, setMobileError] = useState("");
    const [number, setNumber] = useState(userData.mobile_number);
    const [otpPopuOpen, setotpPopuOpen] = useState(false);
    const [timer, setTimer] = useState(120);
    const [showResendLink, setShowResendLink] = useState(false);
    const [hasResent, setHasResent] = useState(false); // Track if OTP has been resent
    const [showResendText, setShowResendText] = useState(true); // Track if OTP has been resent
    const [copied, setCopied] = useState(false);

    React.useEffect(() => {
        if (otpPopuOpen && timer > 0 && !hasResent) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (otpPopuOpen && timer === 0 && !hasResent) {
            setShowResendLink(true);
        }
    }, [otpPopuOpen, timer, hasResent]);


    React.useEffect(() => {
       const loadRecaptchaScript = () => {
           const script = document.createElement('script');
           script.src = "https://www.google.com/recaptcha/api.js";
           script.async = true;
           document.body.appendChild(script);
       };

       loadRecaptchaScript();
   }, []);


   const firebaseConfig = {
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        projectId: firebaseProjectId,
        storageBucket: firebaseStorageBucket,
        messagingSenderId: firebaseMessagingSenderId,
        appId: firebaseAppId,
        measurementId: firebaseMeasurementId
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const photoUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (file === null || file === undefined) {
            return;
        }
        const profileImageSize = 3000000; // 3 MB
        if (file.size > profileImageSize) {
            alert("Profile Image cannot be more than 3MB");
            return;
        }
        reader.onloadend = () => {
            setProfileFile(file);
            setShowSave(file !== null && file !== undefined);
            setImagePreviewUrl(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleFirstNameChange = (event) => {
        const value = event.target.value;
        setFirstName(value);
        setShowSave(userData.first_name !== value);
    }

    const handleLastNameChange = (event) => {
        const value = event.target.value;
        setLastName(value);
        setShowSave(userData.last_name !== value);
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        setisUploading(true);
        const formData = new FormData();
        if (profileFile !== null && profileFile !== undefined) {
            formData.append('file', profileFile);
        }
        if (firstName !== userData.first_name) {
            formData.append('first_name', firstName);
        }
        if (lastName !== userData.last_name) {
            formData.append('last_name', lastName);
        }
        if (upiId !== userData.user_upi_id) {
            formData.append('user_upi_id', upiId);
        }
        if (instaHandle !== userData.insta_handle) {
            formData.append('insta_handle', instaHandle);
        }

        /*        if (number !== userData.mobile_number) {
                    formData.append('mobile_number', upiId);
                }*/
        userService.updatePersonalInfo(formData)
            .then((res) => {
                setisUploading(false);
                setProfileFile(null);
                setUserData(userService.getLocalStorageData());
                setUpiIdSet(userService.getLocalStorageData().user_upi_id?1:0)
                setInstaHandleSet(userService.getLocalStorageData().insta_handle?1:0)
            })
            .catch((err) => {
                if ( err && err.message && err.message.startsWith("The UPI ID ")) {
                    setShowUPIError(true)
                    setUpiError(err.message)
                }  else if ( err && err.message && err.message.startsWith("You already have another UPI ID")) {
                    setShowUPIError(true)
                    setUpiError(err.message)
                }
                setisUploading(false);
            })
        setShowSave(false);
    }

    const handleClipBoardClick = (e) => {
        e.preventDefault();
        copyTextToClipboard(userData.referral_link);
        setCopied(true); // Set copied to true to show feedback

        // Reset copied state after 2 seconds
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }


    const handleUpiIdChange = (event) => {
        let value = event.target.value.trim();
        setUpiId(value);
        setShowSave(userData.user_upi_id !== value && value!=="");
    }

    const handleInstaChange = (event) => {
        let value = event.target.value.trim();
        setInstaHandle(value);
        setShowSave(userData.insta_handle !== value && value!=="");
    }

    const handleResendOtp = () => {
        // Logic to resend OTP
        setHasResent(true);
        setShowResendText(false);
        setShowResendLink(false);
        setTimer(120); // Reset the timer after resending OTP
        sendOTP()
    };


    const handleMobileNumberChange = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        const value = event.target.value;
        setNumber(value);
        const pattern = /^[6789]\d{9}$/;
        // Test the phone number against the regular expression
        setCanGenerateOtp(pattern.test(value));
    }

    // const [recaptchaInitialized, setRecaptchaInitialized] = useState(false);
    // React.useEffect(() => {
    //     if (!recaptchaInitialized) {
    //         setupRecaptcha();
    //         setRecaptchaInitialized(true);
    //     }
    // }, []);

    const setupRecaptcha = () => {
        if (!window.recaptchaVerifier) {
            try {
                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        // reCAPTCHA solved - can proceed with OTP verification
                        // signInWithPhoneNumberInternal()
                    }
                }, auth);
                window.recaptchaVerifier.render().then((widgetId) => {
                    window.recaptchaWidgetId = widgetId;
                });
            } catch (e) {
                setShowMobileError(true)
                setMobileError("Captcha failed - Please try again after sometime.")
                if (window.grecaptcha && window.recaptchaWidgetId) {
                    window.grecaptcha.reset(window.recaptchaWidgetId);
                }
            }
        }
    }


    // Send OTP
    const sendOTP = async () => {
        await setupRecaptcha();
        signInWithPhoneNumberInternal()
    };

    const signInWithPhoneNumberInternal = () => {
        const phoneNumber = "+91"+number
        signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
            .then(function(confirmationResult) {
                window.confirmationResult = confirmationResult;
                const otpPopup = document.getElementById('otp-popup');
                setotpPopuOpen(true)
                otpPopup.style.display = 'flex';
            }).catch((error) => {
                if (error.code === 'auth/too-many-requests') {
                    setShowMobileError(true)
                    setMobileError("Please wait for sometime before requesting a new OTP.")
                } else if (error.code === 'auth/quota-exceeded') {
                    setShowMobileError(true)
                    setMobileError("Please wait for sometime before requesting a new OTP.")
                }
                // Handle the SESSION_EXPIRED error
                else if (error.code === 400 && error.message === "SESSION_EXPIRED") {
                    return
                }
                // Handle the Error code: 39 (backend error)
                else if (error.code === 503 && error.message.includes("Error code: 39")) {
                    // Do nothing for Error code: 39
                    return
                }
                else {
                    setShowMobileError(true)
                    setMobileError("Please try again after sometime.")
                }
                    handleCross()
            });
    };


    // Function to verify OTP
    const verifyOTP = () => {
        const otpBoxes = document.querySelectorAll('.otp-box');
        let otpCode = '';

        // Concatenate values from all OTP input fields
        otpBoxes.forEach(box => {
            otpCode += box.value;
        });

        window.confirmationResult.confirm(otpCode).then(function(result) {
            const formData = new FormData();
            formData.append("mobile_number", number)
            userService.saveMobileNumber(formData)
                .then((res) => {
                    setUserData(res);
                    setNumberVerified(res.mobile_number?1:0)
                })
                .catch((err) => {
                    setShowMobileError(true)
                    setMobileError("Something went wrong, please try again later.")
                    handleCross()
                })
            handleCross()
        }).catch((error) => {
            // Handle the SESSION_EXPIRED error
            if (error.code && error.message ){
               if ( error.code === 400 && error.message === "SESSION_EXPIRED") {
                   return;
               }// Handle the Error code: 39 (backend error)
               else if (error.code === 503 && error.message.includes("Error code: 39")) {
                   // Do nothing for Error code: 39
                   return
               }
            }
            setShowMobileError(true)
            setMobileError("Something went wrong, please try again later.")
            handleCross()
        });
    }

    const handleCross = () => {

        if (window.grecaptcha && window.recaptchaWidgetId) {
            window.grecaptcha.reset(window.recaptchaWidgetId);
        }
        setotpPopuOpen(false)
        setTimer(120)
        setShowResendText(true);
        setHasResent(false); // Ma
        document.querySelectorAll('.otp-box').forEach(input => input.value = '');
        const otpPopup = document.getElementById('otp-popup');
        otpPopup.style.display = 'none';
    }


    const handleInput = (element, index) => {
        const otpBoxes = document.querySelectorAll('.otp-box');
        const submitButton = document.getElementById('submit-otp');

        otpBoxes.forEach((box, index) => {


            box.addEventListener('input', function () {

                if (!/^\d$/.test(box.value)) {
                    box.value = ''; // Clear the input if it isn't a digit
                }


                // Move to the next input box if the current one is filled
                if (box.value.length === 1 && index < otpBoxes.length - 1) {
                    otpBoxes[index + 1].focus();
                }

                // Enable the submit button if all boxes are filled
                const allFilled = Array.from(otpBoxes).every(input => input.value.length === 1);
                submitButton.disabled = !allFilled;
                if ( allFilled) {
                    submitButton.style.background = "green"
                }else {
                    submitButton.style.background = "none"
                }

            });

            box.addEventListener('keydown', function (e) {
                // Allow backspace to move focus to the previous input
                if (e.key === 'Backspace' && box.value.length === 0 && index > 0) {
                    otpBoxes[index - 1].focus();
                }
            });
        });

        submitButton.addEventListener('click', verifyOTP);


}



    return (
        <div>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto">

                        {/*<h3 >*/}
                        {/*    Personal Details</h3>*/}

                        {/*<p style={{ marginTop:"10px",fontSize:"small",fontWeight: "100" }}>Joined on: {userData.joined_at}</p>*/}
                        <Row className="text-center content-center">
                            <div style={{width: "100%"}}>
                                <label className="custom-file-upload fas">
                                    <div className="img-wrap img-upload">
                                        <img src={imagePreviewUrl} width="150px" height="150px" style={{
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            border: "2px solid grey"
                                        }}/>
                                        <p style={{marginTop: "5px", color: "grey"}}>{userData.email}</p>
                                    </div>
                                    <input id="photo-upload" type="file" accept="image/jpeg, image/png"
                                           onChange={photoUpload}/>
                                </label>
                            </div>
                        </Row>

                        {isUploading && (
                            <div style={{
                                position: "fixed",
                                top: "0",
                                left: "0",
                                width: "100vw",
                                height: "100%",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Row style={{
                                    backgroundColor: "black",
                                    padding: "20px",
                                    color: "white",
                                    borderRadius: "20px",
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <CircularProgress thickness={6} size={50}/>
                                    <h4 style={{display: "contents"}}>Updating your details...</h4>
                                </Row>
                            </div>
                        )}


                        <h5 className="text-center" style={{fontWeight: "bold"}}>
                            UNIQUE REFERRAL LINK
                        </h5>

                        <Row
                            style={{
                                backgroundColor: "transparent",
                                padding: "0px",
                                marginLeft: "0px",
                                display: "flex", // Ensures children are laid out horizontally
                                justifyContent: "center", // Centers content horizontally
                                alignItems: "center", // Aligns content vertically in the middle
                            }}
                        >
                            <p
                                className="text-center"
                                style={{
                                    color: "#B00610",
                                    width: "auto",
                                    margin: "0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {userData.referral_link}
                            </p>
                            <ContentCopyIcon
                                className="copy-icon"
                                onClick={handleClipBoardClick}
                                style={{
                                    width: "auto",
                                    marginLeft: "10px",
                                    fontSize: "36px",
                                    cursor: "pointer",
                                }}
                            />
                            {copied && (
                                <span style={{marginLeft: "10px", color: "green"}}>Copied</span>
                            )}

                            {/* Add the press action style */}
                            <style jsx>{`
                                .copy-icon:active {
                                    transform: scale(0.9); /* Shrink slightly on press */
                                    transition: transform 0.1s ease; /* Smooth shrinking */
                                }
                            `}</style>
                        </Row>
                        <h5 style={{fontWeight: "bold", marginTop: "25px"}}>NAME</h5>
                        <input style={{
                            borderRadius: "15px",
                            border: "1px solid #b7b7b7",
                            padding: "10px",
                            transition: "0.2s",
                        }} placeholder="First Name" value={firstName} onChange={handleFirstNameChange}/>

                        <input style={{
                            borderRadius: "15px",
                            border: "1px solid #b7b7b7",
                            padding: "10px",
                            marginTop: "1.4rem",
                            transition: "0.2s"
                        }} placeholder="Last Name" value={lastName} onChange={handleLastNameChange}/>

                        <h5 style={{fontWeight: "bold", marginTop: "25px"}}>UPI ID</h5>

                        <Row style={{backgroundColor: "transparent", padding: "0px", marginLeft: "0px"}}>
                            <input style={{
                                borderRadius: "15px",
                                border: "1px solid #b7b7b7",
                                padding: "10px",
                                transition: "0.2s",
                            }} placeholder="UPI ID" value={upiId} disabled={upiIdSet} onChange={handleUpiIdChange}/>
                        </Row>
                        {(upiIdSet == true) &&
                            <p style={{fontSize: "small", color: "grey", marginLeft: "1px"}}>*This can not be
                                updated. Mail us at contact-us@con10craze.com for any queries. </p>
                        }

                        <Alert color="danger" style={{marginTop: "5px"}} isOpen={showUPIError}>
                            <Container>
                                {upiError}
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setShowUPIError(false)}
                                >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                </button>
                            </Container>
                        </Alert>

                        <h5 style={{fontWeight: "bold", marginTop: "25px"}}>Have Fun connecting Instagram</h5>
                        <Row style={{backgroundColor: "transparent", padding: "0px", marginLeft: "0px"}}>
                            <input style={{
                                borderRadius: "15px",
                                border: "1px solid #b7b7b7",
                                padding: "10px",
                                transition: "0.2s",
                            }} placeholder="Your Instagram handle" value={instaHandle}
                            disabled={instaHandleSet}
                            onChange={handleInstaChange}/>
                        </Row>


                        <h5 style={{fontWeight: "bold", marginTop: "25px"}}>PASSWORD</h5>

                        <Row style={{backgroundColor: "transparent", padding: "0px", marginLeft: "0px"}}>
                            <input type="password" value="************" style={{
                                borderRadius: "15px",
                                border: "1px solid #b7b7b7",
                                padding: "10px",
                                marginTop: "1.4rem",
                                transition: "0.2s"
                            }}/>

                            <Button className="center" style={{
                                marginTop: "1.4rem",
                                marginLeft: "0.5rem",
                                padding: "10px",
                            }}
                                    color="info" onClick={() => setShowChangePwd(true)}>Change Password</Button>
                        </Row>


                        {showSave &&
                            <div>
                                <Button className='btn-round center' color="success" size="lg"
                                        style={{
                                            margin: "auto",
                                            display: "block",
                                            marginTop: "40px",
                                            flex: "content",
                                            flexDirection: "column"
                                        }}
                                        onClick={handleSubmit}>Save</Button>
                            </div>
                        }
                        <h5 style={{fontWeight: "bold", marginTop: "25px"}}>MOBILE NUMBER</h5>
                        <Container>
                            <Row style={{backgroundColor: "transparent", padding: "0px"}}>
                                <input
                                    style={{
                                        borderRadius: "15px",
                                        border: "1px solid #b7b7b7",
                                        padding: "10px",
                                        transition: "0.2s",
                                    }} maxLength="10"

                                    placeholder="MOBILE NUMBER"
                                    value={number} disabled={numberVerified} onChange={handleMobileNumberChange}/>

                                <div id="recaptcha-container"></div>
                                {!numberVerified && <Button
                                    id="verify-phone"
                                    className="center" style={{
                                    marginTop: "1.4rem",
                                    marginLeft: "0.5rem",
                                    padding: "10px",
                                }}
                                    disabled={!canGenerateOtp}
                                    color="info" onClick={() => sendOTP()}>REGISTER</Button>
                                }

                                {(numberVerified === 1) && <CheckCircleIcon style={{
                                    marginTop: "1.4rem",

                                    color: "green", fontSize: "larger"
                                }}/>
                                }
                            </Row>


                            <Alert color="danger" style={{marginTop: "5px"}} isOpen={showMobileError}>
                                <Container>
                                    {mobileError}
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={() => setShowMobileError(false)}
                                    >
                                    <span aria-hidden="true">
                                        <i className="now-ui-icons ui-1_simple-remove"></i>
                                    </span>
                                    </button>
                                </Container>
                            </Alert>
                        </Container>

                        <div id="otp-popup"
                             style={{
                                 position: "fixed",
                                 top: "0", left: "0", width: "100%", height: "100%",
                                 zIndex: 1000
                                 , display: "none", justifyContent: "center", alignItems: "center"
                             }}>
                            <div id="otp-modal" style={{
                                position: "relative",
                                backgroundImage: `url(${games})`,
                                width: "80%",
                                padding: "20px",
                                borderRadius: "8px",
                                border: "2px solid #dddddd",
                                textAlign: "center"
                            }}>
                                <button id="close-popup"
                                        onClick={handleCross}
                                        color="red" style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "transparent",
                                    border: "none",
                                    fontSize: "18px",
                                    cursor: "pointer"
                                }}>X
                                </button>
                                <p style={{marginTop: "30px", padding: "10px"}}>Enter the OTP sent on <br/><p
                                    style={{fontWeight: "bold"}}>{number}</p></p>
                                <div style={{marginBottom: "20px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50px"
                                         height="50px" style={{fill: "#4CAF50"}}>
                                        <path
                                            d="M17,1H7C5.34,1,4,2.34,4,4v16c0,1.66,1.34,3,3,3h10c1.66,0,3-1.34,3-3V4C20,2.34,18.66,1,17,1z M14,20.75c0,0.41-0.34,0.75-0.75,0.75h-2.5C10.34,21.5,10,21.16,10,20.75S10.34,20,10.75,20h2.5C13.66,20,14,20.34,14,20.75z M17,17H7V5h10V17z"/>
                                    </svg>
                                </div>
                                <div className="toast-body">
                                    <div id="otp-inputs">
                                        <input type="text" maxLength="1" className="otp-box" style={{
                                            width: "15%",
                                            height: "40px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            margin: "1px"
                                        }} onInput={() => handleInput(this, 0)}/>

                                        <input type="text" maxLength="1" className="otp-box" style={{
                                            width: "15%",
                                            height: "40px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            margin: "1px"
                                        }} onInput={() => handleInput(this, 1)}/>
                                        <input type="text" maxLength="1" className="otp-box" style={{
                                            width: "15%",
                                            height: "40px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            margin: "1px"
                                        }} onInput={() => handleInput(this, 2)}/>
                                        <input type="text" maxLength="1" className="otp-box" style={{
                                            width: "15%",
                                            height: "40px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            margin: "1px"
                                        }} onInput={() => handleInput(this, 3)}/>
                                        <input type="text" maxLength="1" className="otp-box" style={{
                                            width: "15%",
                                            height: "40px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            margin: "1px"
                                        }} onInput={() => handleInput(this, 4)}/>
                                        <input type="text" maxLength="1" className="otp-box" style={{
                                            width: "15%",
                                            height: "40px",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            margin: "1px"
                                        }} onInput={() => handleInput(this, 5)}/>
                                    </div>

                                    <button id="submit-otp" onClick={verifyOTP} disabled
                                            style={{
                                                borderRadius: "10px",
                                                marginTop: "1.4rem",
                                                marginLeft: "0.5rem",
                                                padding: "10px"
                                            }}>Submit
                                    </button>
                                    {showResendText && <div>
                                        {showResendLink ? (<span
                                            onClick={handleResendOtp}
                                            style={{
                                                color: 'white', textDecoration: 'underline', cursor: 'pointer',
                                            }}
                                        >Resend OTP
                                        </span>) : (<span>Resend OTP in {timer} seconds</span>)}
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <ChangePassword showChangePwd={showChangePwd} setShowChangePwd={setShowChangePwd}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyProfile;