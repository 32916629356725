import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { userService } from "services/user.service";

const allowedDistributionPercentages = {
    10:[25,15,12,12,10,8,6,4,4,4],
    9: [28,15,13,12,10,10,5,4,3],
    8: [28,18,15,15,10,5,5,4],
    7: [30,20,15,15,10,5,5],
    6: [35,20,15,15,10,5],
    5: [35,25,15,15,10],
    4: [45,30,15,10],
    3: [50,30,20],
    2: [65,35],
    1: [100],
    0: [0]
};

const CreatePrivateContest = () => {
    let formatter = Intl.NumberFormat('en', { notation: 'standard' });
    const navigate = useNavigate();
    const [contestDetails, setContestDetails] = useState({
        contestName: '',
        maxPlayers: '',
        winnerDistribution: '',
        gamePrice: '',
        numberWinners: 0,
        winnerDistribution: [],
    });
    const [contestCreationScreen, setContestCreationScreen] = useState(1);
    const [successMsg, setSuccessMsg] = useState(null);
    const [errors, setErrors] = useState(null);

    const calculateWinners = (numberOfPlayers) => {
        const totalPrizePool = numberOfPlayers * contestDetails.gamePrice;
        const userPrizePool = parseInt(totalPrizePool * 0.95);

        let winnersCount = setNumberWinners(numberOfPlayers);
        const distributionPercentages = allowedDistributionPercentages[winnersCount] || [];
        // Calculate prize for each rank based on the percentage
        const calculatedDistribution = distributionPercentages.map((percentage, index) => ({
            rank: index + 1,
            prize: parseInt((percentage / 100) * userPrizePool),
        }));
        setContestDetails((prevDetails) => ({
            ...prevDetails,
            ["winnerDistribution"]: calculatedDistribution,
        }));
    }

    const getUsersPrizePool = () => {
        let poolSum = 0;
        for (var i = 0;i < contestDetails.winnerDistribution.length; ++i) {
            poolSum += parseInt(contestDetails.winnerDistribution[i].prize);
        }
        return poolSum;
    }

    const setNumberWinners = (numberOfPlayers) => {
        const maxAllowed = parseInt(Math.ceil(numberOfPlayers * 0.4));
        let winnersCount = maxAllowed;
        if (!winnersCount || winnersCount < 1 || winnersCount > maxAllowed) {
            winnersCount = maxAllowed || 1; // Ensure at least one winner
        }
        winnersCount = Math.min(winnersCount, 10); // Cap the winners to 10
        setContestDetails((prevDetails) => ({
            ...prevDetails,
            ["numberWinners"]: winnersCount,
        }));
        return winnersCount;
    }

    // Handler to update form state
    const handleContestDetailsChange = (e) => {
        const { name, value } = e.target;
        setErrors(null);
        if (name == "contestName") {
            if (value === null || value === undefined || value.trim().length === 0) {
                setErrors("Please enter a valid contest name");
                return;
            }
            if (value.trim().length > 30) {
                setErrors("Contest name should be less than 30 characters");
                return;
            }
        }

        if (name === "maxPlayers") {
            if (parseInt(value) < 2 || parseInt(value) > 1000) {
                setErrors("Maximum number of allowed players should be between 2 and 1000");
                return;
            }
            setContestDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
            calculateWinners(value);
        } else if (name === "numberWinners") {
            const totalPrizePool = contestDetails.maxPlayers * contestDetails.gamePrice;
            const userPrizePool = parseInt(totalPrizePool * 0.95);

            const maxAllowed = parseInt(Math.ceil(contestDetails.maxPlayers * 0.4));
            let winnersCount = Math.min(value, Math.min(maxAllowed, 10)); // Cap the winners to 10
            const distributionPercentages = allowedDistributionPercentages[winnersCount] || [];
            // Calculate prize for each rank based on the percentage
            const calculatedDistribution = distributionPercentages.map((percentage, index) => ({
                rank: index + 1,
                prize: parseInt((percentage / 100) * userPrizePool),
                prizePercent: parseInt(percentage)
            }));
            setContestDetails((prevDetails) => ({
                ...prevDetails,
                ["winnerDistribution"]: calculatedDistribution,
            }));
            setContestDetails((prevDetails) => ({
                ...prevDetails,
                ["numberWinners"]: winnersCount,
            }));
        } else if (name === "gamePrice") {
            if (parseInt(value) < 1 || parseInt(value) > 10000) {
                setErrors("Allowed entry fee per person should be between ₹1 and ₹10000");
                return;
            }
            const totalPrizePool = contestDetails.maxPlayers * value;
            const userPrizePool = parseInt(totalPrizePool * 0.95);
            const distributionPercentages = allowedDistributionPercentages[contestDetails.numberWinners] || [];
            // Calculate prize for each rank based on the percentage
            const calculatedDistribution = distributionPercentages.map((percentage, index) => ({
                rank: index + 1,
                prize: parseInt((percentage / 100) * userPrizePool),
                prizePercent: parseInt(percentage)
            }));
            setContestDetails((prevDetails) => ({
                ...prevDetails,
                ["winnerDistribution"]: calculatedDistribution,
            }));
            setContestDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        } else {
            setContestDetails((prevDetails) => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };

    const handleContestSubmit = (e) => {
        e.preventDefault();
        userService.createPrivateContest(contestDetails)
        .then((game_uuid) => {
            setSuccessMsg('Congratulations, your contest has been created. Now invite your friends and start playing!');
            setTimeout(() => {
                navigate('/game/' + game_uuid, { state: {"shareGame": true} });
            }, 1500);
        })
        .catch((err) => {
            console.log(err);
            if (err && err.message && err.message.length > 0) {
                setErrors(err.message);
            } else {
                setErrors('Something went wrong, please try again later.')
            }
        })
    };

    const createContestDialog = () => {
        return (
            <>
                {/*<h3>Create Friendly match (no money required)</h3>*/}
                <div style={{ marginBottom: "0.7rem" }}>
                    <span style={{ fontStyle: "italic" }}>*Contest will start in 72 hours. Invite your friends and make sure they join your contest before then.</span>
                </div>
                {contestCreationScreen === 1 && <div className="create-contest-container">
                    <form onSubmit={handleContestSubmit} className="contest-form">
                        <div className="form-group">
                            <input
                                type="text"
                                id="contestName"
                                name="contestName"
                                placeholder='Contest Name'
                                value={contestDetails.contestName}
                                onChange={handleContestDetailsChange}
                                required
                                style={{ width: "100%", marginBottom: "15px"}}
                            />
{/*                            <span style={{ display: "block", textAlign: "end", fontWeight: "lighter", fontSize: "small" }}>0/25</span>*/}
                        </div>

                        <div className="form-group">
                            <Row style={{ margin: "0px" }}>
                                <Col className='pull-left' xs="5" style={{ padding: "0px" }}>
                                    <input
                                        type="number"
                                        id="maxPlayers"
                                        name="maxPlayers"
                                        min={2}
                                        max={1000}
                                        value={contestDetails.maxPlayers}
                                        placeholder='Maximum players'
                                        onChange={handleContestDetailsChange}
                                        required
                                        style={{ width: "100%" }}
                                    />
                                </Col>
                                <Col xs="1" style={{ padding: "0px" }}></Col>
                                <Col xs="5" style={{ padding: "0px" }}>
                                    <input
                                        type="number"
                                        id="gamePrice"
                                        name="gamePrice"
                                        min={5}
                                        value={contestDetails.gamePrice}
                                        placeholder='Entry Fee'
                                        onChange={handleContestDetailsChange}
                                        required
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="form-group">
                            {/*                            <span style={{ display: "block", textAlign: "left", fontWeight: "lighter", fontSize: "small" }}>Game Start Time</span>
                            <input
                                type="datetime-local"
                                id="startTime"
                                name="startTime"
                                value={contestDetails.startTime}
                                onChange={handleContestDetailsChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <span style={{ display: "block", textAlign: "left", fontWeight: "lighter", fontSize: "small" }}>Game End Time</span>
                            <input
                                type="datetime-local"
                                id="endTime"
                                name="endTime"
                                value={contestDetails.endTime}
                                onChange={handleContestDetailsChange}
                                required
                            />*/}
                        </div>

                        <div className="prize-distribution-section">
                            <h4>Choose Prize Distribution</h4>
                            <div className="form-group">
                                <label htmlFor="numWinners" style={{ marginRight: "0.5rem" }}>Select Number of Winners:</label>
                                <input
                                    type="number"
                                    id="numberWinners"
                                    name="numberWinners"
                                    min="1"
                                    max="10"
                                    value={contestDetails.numberWinners}
                                    onChange={handleContestDetailsChange}
                                />
                            </div>

                            <div className="winners-list">
                                <h4 style={{ margin: "0px" }}>Winners and Prizes</h4>
                                <ul style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                    gap: "1rem",
                                    listStyle: "none",
                                    marginBottom: "0px",
                                    padding: "0px"
                                }}>
                                    {contestDetails.winnerDistribution.map((winner) => (
                                        <li key={winner.rank} style={{color:"green", fontWeight:"bold"}}>
                                            Rank {winner.rank}: ₹{formatter.format(winner.prize)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>


                        <Button color="info" className="ml-auto mr-auto" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Create Contest
                        </Button>
                    </form>

{/*                    <Button onClick={() => setContestCreationScreen(2)}>Next</Button>*/}

                </div>}

                {contestCreationScreen === 2 && <div className="create-contest-container">
                    Screen 2
                    <Button onClick={() => setContestCreationScreen(1)}>Back</Button>
                    <hr />

                    <Row>
                        <Col className='ml-auto mr-auto'>
                            <i className="now-ui-icons sport_trophy" style={{ color: "goldenrod", marginRight: "0.4rem", fontSize: "large" }} ></i>
                            Prize Pool: ₹ -
                        </Col>
                    </Row>

                </div>}
                <hr />

                <Row>
                    <Col className='ml-auto mr-auto' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className="now-ui-icons sport_trophy" style={{ color: "goldenrod", marginRight: "0.4rem", fontSize: "x-large", fontWeight: "bold" }} ></i>
                        Prize Pool:&nbsp;&nbsp;<span style={{ fontWeight: "bold" }}>₹{formatter.format(getUsersPrizePool())}</span>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <div className="prize-distribution-section">
            {createContestDialog()}
            <Alert color="success" isOpen={successMsg !== null && successMsg !== undefined} style={{ marginTop: "10px" }}>
                <Container>
                    {successMsg}
                </Container>
            </Alert>
            <Alert color="danger" isOpen={errors !== null && errors !== undefined} style={{ marginTop: "10px" }}>
                <Container>
                    {errors}
                </Container>
            </Alert>
        </div>
    );
}

export default CreatePrivateContest;
