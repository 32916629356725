import React, { useState } from "react";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';


import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// reactstrap components
// import {
// } from "reactstrap";


// sections for this page
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import Accordion from "./index-sections/Accordion.js";
import { userService } from "services/user.service.js";
import SEO from "utils/seo.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import how_to_thumb from "assets/img/how_to_thumb.png";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

// import required modules
import { EffectCards } from 'swiper/modules';
import {
  pixelCreatorRegisterConversionTracking,
} from "./conversion-script";
import {MyTimer} from "../utils/helper";
import {useTimer} from "react-timer-hook";
import { ReferralBonus } from "utils/constants.js";
import coinsIcon from "../assets/img/coins-icon.png";
import referIcon from "../assets/img/refer-icon.png";
import dailyIcon from "../assets/img/daily-icon.png";
import opinionIcon from "../assets/img/opinion-icon.png";

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const CounterBox = ({ end, label }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger the count once
    threshold: 0.1, // Trigger when 10% of the element is in view
  });

  let threshold = 1;
  let fixedDecimal = 1;
  end = parseInt(end);
  if (label === "Winnings") {
    threshold = 100;
    fixedDecimal = 2;
  } else if (label === "Games") {
    threshold = 10;
  }

  let roundedEnd = end;
  if (threshold > 1) {
    roundedEnd = ( parseInt(end / threshold)).toFixed(fixedDecimal) * threshold;
  }

  return (
    <div ref={ref} className="counter-box">
      <p>{inView ? <CountUp start={0} end={end} duration={2.75} /> : 0}</p>
      <p style={{ marginTop: "0.75rem" }}>{label}</p>
    </div>
  );
};

const Counters = ({ gamesStats }) => {
  return (
    <div className="section section-basic" id="basic-elements" style={{padding: "5px"}}>
      <Container>
        <div className="counters-container">
          {gamesStats.map((counter, index) => (
            <Col className="text-center ml-auto mr-auto" xs="4" style={{ padding: "5px" }}>
              <CounterBox key={index} end={counter.end} label={counter.label} />
            </Col>
          ))}
        </div>
      </Container>
    </div>
  );
};

const ChallengeButton = ({ onClick }) => {
  return (
      <button 
          onClick={onClick} 
          style={{
              backgroundColor: '#ff4500', 
              color: 'white', 
              padding: '10px 20px', 
              fontSize: '16px',
              borderRadius: '8px',
              cursor: 'pointer',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}>
          🔥 Challenge Your Friends!
      </button>
  );
};

function LandingPage() {
  const howToPlayVideoSrc = "https://d78eqrbdvopvl.cloudfront.net/how_to_play.mp4";
  const [artists, setArtists] = useState([]);
  const [gamesStats, setGamesStats] = useState([]);
  const [trendingData, setTrendingData] = useState({});
  const navigate = useNavigate();
  const userData = userService.getLocalStorageData();
  const referralLink = userData ? ("referral_link" in userData ? userData["referral_link"] : null): null;
  const referPos = referralLink ? (referralLink.indexOf("refer=") != -1 ? referralLink.indexOf("refer=") : null) : null;
  const referralId = referPos ? referralLink.substr(referPos + 6) : null;
  const [upcoming, setUpcoming] = React.useState();
  const [gamesLoaded, setGamesLoaded] = useState(false);

/*  const users = [
    {
      id: 1,
      profilePicture: 'path/to/profile1.jpg',
      name: 'A',
      amount: 5000
    },
    {
      id: 2,
      profilePicture: 'path/to/profile2.jpg',
      name: 'B',
      amount: 7000
    },
    {
      id: 3,
      profilePicture: 'path/to/profile2.jpg',
      name: 'C',
      amount: 3400
    },
    {
      id: 4,
      profilePicture: 'path/to/profile2.jpg',
      name: 'D',
      amount: 2300
    },
    // Add more users as needed
  ];
  const [displayedUsers, setDisplayedUsers] = useState(users.slice(0, 3));
  const [animationClass, setAnimationClass] = useState('');*/
// Function to get affiliate ID from the URL
  const [searchParams, setSearchParams] = useSearchParams();
  function getAffiliateIDFromURL() {
    return searchParams.get("refId")
  }

  React.useEffect(() => {
    const affiliateID = getAffiliateIDFromURL();
    if (affiliateID ) {
      userService.writeSearchParams(searchParams)
    }
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    userService.getFantasyUpcomingGames()
        .then((res) => {
          setUpcoming(res[0]);
          setGamesLoaded(true)
        })
        .catch((err) => { })



  userService.getGamesStats()
      .then((res) => {
        setGamesStats(res);
      })
      .catch((err) => {})

      userService.getTrendingStats()
      .then((res) => {
        setTrendingData(res);
      })
      .catch((err) => {})

      if (userService.isLoggedIn()) {
        userService.getUserWalletDetails();
        // also redirect logged in users to fantasy games page to see al games?
      }
      userService.getFantasyArtists()
        .then((res) => {
          setArtists(res);
        })
        .catch((err) => { })
      return function cleanup() {
        document.body.classList.remove("index-page");
        document.body.classList.remove("sidebar-collapse");
  //      clearInterval(interval);
      };
    }, []);


  const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    flexWrap: 'wrap', // Allow wrapping of divs
    padding: "5px",
  };

  const divStyles = {
    position: 'relative',
    width: '100%',
    height: '140px',
    backgroundColor: '#f0f0f0', // Grey background color
    border: '1px solid #dddddd',
    borderRadius: '10px', // Rounded corners
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginBottom:"20px",// Margin between divs for spacing
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" /* Shadow for 3D effect */
  };

  const numberCircleStyles = {
    position: 'absolute',
    top: '-15px', // Adjust to be half inside and half outside
    left: '15px', // Adjust to be half inside and half outside
    width: '30px',
    height: '30px',
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    border: '1px solid #dddddd',
  };


  const containerStylesPoint = {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    width: "100%",
    margin: "auto",
    borderRadius:"5px",
    boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.1)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "14px",
  };

  const infoCardStyles = {
    // backgroundColor: '#f5f5f5',
    // border: '1px solid #dddddd',
    // borderRadius: '10px',
    margin: '10px',

    width: '100%',
    textAlign: 'center',
  };

  const headerStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '20px',
    padding: "5px"
  };

  const detailStyles = {
    fontSize: '16px',
    color: '#4D4D4d',
    marginBottom: '10px',
    padding: "10px"

  };

  const formulaStyles = {
     fontSize: '18px',
    //    color: '#4CAF50',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const handleCopy = () => {
    const signupLink = `https://con10craze.com?refer=${referralId}`;
    navigator.clipboard.writeText(signupLink);
  };

  const handleInvite = () => {
    // Add your invite functionality here
    const signupLink = `https://con10craze.com?refer=${referralId}`;
    const message = `Hey! 🙌 I’ve been playing on Con10Craze, India’s 1st Social Fantasy Sports platform, and it’s amazing! 🏆 Sign up using my referral link and we’ll BOTH get ₹${ReferralBonus} as a bonus! 🎉\n\nClick here to join: ${signupLink}\n\nLet’s play and win together! 🚀`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const ScoreInformation = () => {
    return (
      <div style={containerStylesPoint}>
        <div style={infoCardStyles}>
          <div style={headerStyles}><p style={{ fontSize: "18px", fontWeight: "bold" }}>How Your Score is Calculated</p></div>
          <div style={detailStyles}>Each Like: <span style={{ color: '#4CAF50', fontWeight: 'bold' }}>1 point</span></div>
          <div style={detailStyles}>Each Comment: <span style={{ color: '#4CAF50', fontWeight: 'bold' }}>2 points</span></div>
          <div style={formulaStyles}>
            Creator Score = <br />
            (1*<span style={{ color: '#4CAF50' }}>{'Likes'}</span> + 2*<span style={{ color: '#4CAF50' }}>{'Comments'}</span>) / <span style={{ color: '#4CAF50' }}>{'Followers'}</span>*100
          </div>
          <div style={detailStyles}>
            Your score is calculated as the sum of scores of the creators in your team.
          </div>
        </div>
      </div>
    );
  };

  const howToSection = () => {
    return <div className="section section-basic" id="basic-elements" style={{padding: "5px"}}>
      <Container>
      <Row>
        <Col className="ml-auto mr-auto text-center" style={{padding: "5px"}}>
          <h2 className="title">
            HOW TO PLAY
          </h2>
          <p>
            Watch this video to learn how to play on Con10Craze and get started today!<br></br><br></br>
            {/*Create your team of favourite Instagram creators, compete based on their engagement, and earn cash rewards. Visit the playground for insider tips on creators and boost your chances of winning. Play, compete, and cash in!<br></br><br></br>*/}
            {/*Are you ready to challenge fellow sports fans?*/}
          </p>
          <video id="teaser_vid"
                 preload="none"
                 frameBorder="0"
                 poster={how_to_thumb}
                 playsInline
                 controls
                 width="100%"
                 title="How to Play Con10Craze Fantasy Sports"
                 style={{borderRadius: "10px", paddingLeft:"20px", paddingRight:"20px"}}
          >
            <source src={howToPlayVideoSrc} type="video/mp4"/>
          </video>
        </Col>
      </Row>

      <Row>
        <Col className="text-center ml-auto mr-auto" lg="6" md="8" style={{padding: "5px"}}>
          <Button
              className="btn-round"
              color="info"
              style={{fontWeight: "bold"}}
              onClick={(e) => {
                e.preventDefault();
                navigate("/fantasy-games")
              }}
              size="lg"
          >
            Start Playing Now!
          </Button>
        </Col>
      </Row>
      </Container>
    </div>
  }

  const explore = () => {
    return (
        <div className="section section-basic">
          <Container >
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">
                Earn
                {/*<CurrencyRupeeIcon className='fas fa-fire-alt' style={{fontSize: "30px", color: "green"}}></CurrencyRupeeIcon>*/}
              </h2>
            </Col>
            <div className="explore-grid" style={{paddingLeft:"20px", paddingRight:"20px"}}>
              <div className="explore-card earn-coins" onClick={() => navigate('/coins')}>
                <img src={coinsIcon} alt="Earn Coins" className="explore-image"/>
                <div className="explore-text-container">
                  <p className="explore-text">Earn coins by doing activities</p>
                </div>
              </div>
              <div className="explore-card refer-friend" onClick={() =>  navigate('/profile', { state: { 'activeTab': 1 } })}>
                <img src={referIcon} alt="Daily Streak" className="explore-image"/>
                <div className="explore-text-container">
                  <p className="explore-text">Refer your friend to earn ₹{ReferralBonus}</p>
                </div>
              </div>
              <div className="explore-card daily-streak" onClick={() => navigate('/profile', { state: { 'activeTab': 0 , scrollToStreaks: true} })}>
                <img src={dailyIcon} alt="Refer a Friend" className="explore-image"/>
                <div className="explore-text-container">
                  <p className="explore-text">Earn bonus by daily streak</p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      );
    };


  const opinion = () => {
    return (
    <div className="section section-basic" id="basic-elements" style={{padding: "0px",marginBottom:"0px"}}>
        <div className="opinion-container">
          <div className="opinion-banner" style={{padding:"0px"}}>
            <img src={opinionIcon} alt="Logo" className="opinion-image"/>
            <p className="opinion-text">Check your opinion</p>
          </div>
        </div>
    </div>
    );
  };



  const freePlay = () => {
    return (
      <div className="section section-basic" id="basic-elements" style={{    color: "black",padding: "5px" }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center">
              <Row>
              <div style={containerStyles}>
              <div className="free-landing-page" style={containerStyles}>
                    <Col style={{marginTop:"5px"}} className="free-column" xs="12" md="6">

                    <div className="promotion-icon">💰</div>
                    <div className="free-text-content">
                        <h2 className="title">Earn Free Cash Bonus Daily</h2>
                        <p>Refer your friends, complete your daily streaks and earn free cash bonus credited to your account instantly!</p>
                      {/*{ userService.isLoggedIn() &&*/}
                      {/*    <Row style={{padding: "10px", width: "100%", marginLeft:"10px",justifyContent: "space-between"}}>*/}
                      {/*      <Col xs="4" style={{color: '#B00610', padding: "0px", fontWeight: 'bold'}}>*/}
                      {/*        <Row style={{padding: "0px", width: "100%", justifyContent: "space-between"}}>*/}
                      {/*        <span style={{padding: "5px", backgroundColor: "white",}}>{referralId}</span>*/}
                      {/*        </Row>*/}
                      {/*        <Row  style={{*/}
                      {/*          padding: "0px",*/}
                      {/*          marginTop:"2px",*/}
                      {/*          width: "100%",*/}
                      {/*          textAlign:"center",*/}
                      {/*          color:"black"*/}
                      {/*        }}>*/}
                      {/*        <span className="referral-link" >*/}
                      {/*          Your Referral Code*/}
                      {/*        </span>*/}
                      {/*        </Row>*/}
                      {/*      </Col>*/}
                      {/*      <Col xs="1" style={{padding: "0px"}}>*/}
                      {/*        <ContentCopyIcon className="center" onClick={(e) => {*/}
                      {/*          handleCopy(e, {referralId})*/}
                      {/*        }} style={{cursor: "pointer", width: "auto", fontSize: "25px",}}/>*/}

                      {/*      </Col>*/}
                      {/*      <Col xs="5" style={{color: 'white', padding: "0px", fontWeight: 'bold',}}>*/}
                      {/*        <Button*/}
                      {/*            style={{*/}
                      {/*              backgroundColor: "green",*/}
                      {/*              marginLeft: "auto",*/}
                      {/*              marginRight: "auto",*/}
                      {/*              marginTop: "0"*/}
                      {/*            }}*/}
                      {/*            size="sm"*/}
                      {/*            onClick={handleInvite}*/}
                      {/*        >*/}
                      {/*          Invite*/}
                      {/*        </Button>*/}
                      {/*      </Col>*/}
                      {/*    </Row>*/}
                      {/*}*/}
                    </div>
                    </Col>
        
                    <Col style={{marginTop:"5px"}} className="free-column" xs="12" md="6">
                      <div className="promotion-icon">🏆</div>
                      <div className="free-text-content">
                        <h2 className="title">Play for Free and Win Big</h2>
                        <p>Use 100% of your Bonuses to Play Our Games for Free. Win real cash and withdraw your winnings effortlessly!</p>
                      </div>
                    </Col>
                  </div>
                  </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const testimonials = () => {
    const testimonials2 = [
      {
        name: 'Aayush',
        date:'28-08-2024',
        image: 'https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg',
        text: 'I wanted to take a moment to express my appreciation for your app. It\'s incredibly user-friendly and well-designed. The features are intuitive, and it consistently performs smoothly. Thank you for creating such a valuable tool—it’s clear a lot of thought and effort went into making it great. Keep up the excellent work!',
        rating: 5,
      },
      {
        name: 'Ritwik P',
        date:'30-08-2024',
        image: 'https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg',
        text: 'Lovely app made loads of money with this with 0 investment. Loved it wohhhoooo',
        rating: 5,
      },
      {
        name: 'Harnaik',
        date:'08-09-2024',
        image: 'https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg',
        text: 'Very good , something new in market , and judging people performance ',
        rating: 5,
      },
      {
        name: 'RP',
        date:'089-09-2024',
        image: 'https://d78eqrbdvopvl.cloudfront.net/Rida_Tharanaa.jpeg',
        text: 'Lovely application. Helped me win money without putting in any. loved it',
        rating: 5,
      },
    ];

    let settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplaySpeed: 2000,
      responsive:[
        {
          breakpoint: 500,
          settings: {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,      
            autoplaySpeed: 2000,
          }
        }
      ]
    };

    return (
      <section class="py-5 testimonials">

        <div className="testimonials-section container">


        <h2 className="section-title">What Our Users Are Saying</h2>
        <Slider {...settings}>

          {testimonials2.map((testimonialItem, index) => {
            return (
              <div className={`${index % 2 === 0 ? '': 'ms-md-auto'}`}>
                <div className={`card ${index % 2 === 0 ? 'bg-gradient-primary': 'bg-gradient-primary'}`}>
                  <div className="card-body">
                    <div className="author align-item-center">
                      <div style={{display: "flex", justifyContent: "center"}}>
                      </div>
                      <div className="name ps-2">
                        <span>{testimonialItem.name}</span>
                      </div>
                      <div style={{fontWeight:"normal", color:"grey"}} className="name ps-2">
                        <span>{testimonialItem.date}</span>
                      </div>
                    </div>
                    <p className="mt-4">{testimonialItem.text}</p>
                    <div className="rating mt-3">
                      <i style={{color:"goldenrod"}} className={`${testimonialItem.rating >= 1 ? 'fas fa-star': 'far fa-star'}`}></i>
                      <i style={{color:"goldenrod"}} className={`${testimonialItem.rating >= 2 ? 'fas fa-star': 'far fa-star'}`}></i>
                      <i style={{color:"goldenrod"}} className={`${testimonialItem.rating >= 3 ? 'fas fa-star': 'far fa-star'}`}></i>
                      <i style={{color:"goldenrod"}}className={`${testimonialItem.rating >= 4 ? 'fas fa-star': 'far fa-star'}`}></i>
                      <i style={{color:"goldenrod"}}className={`${testimonialItem.rating >= 5 ? 'fas fa-star': 'far fa-star'}`}></i>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>


        </div>
      </section>
    )
  }

  const topScorers = () => {
    return (
      <Col className="trending-card top-scorers ml-auto mr-auto" xs="12" md="6">
        <h4 className="sub-title">
        <i className="now-ui-icons sport_trophy"></i>        Top Winners
        </h4>
        {"top_winners" in trendingData && trendingData["top_winners"].map((scorer, index) => (
            <div className="scorer-list" style={{display:"flex", flexDirection:"column", gap:"16px", width:"100%", padding:"0",margin:"auto", marginBottom:"10px"}}>
              <Row className="scorer-item" style={{height:"50px",backgroundColor:"#ffffff", padding:"16px",borderRadius:"8px", display:"flex", justifyContent:"space-between", alignItems:"center", transition:"background-color 0.3s ease, transform 0.2s ease", boxShadow:"0 4px 10px rgba(0, 0, 0, 0.05)"}}>
                <span className="scorer-name" style={{
                  fontWeight: "bold",
                  color: "#1e293b",
                  width: "50%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}>
                              <span className="icon-coin" style={{
                                width: "18px",
                                height: "18px",
                                backgroundSize: "contain"
                              }}></span> {scorer.first_name}
                            </span>
                <span className="scorer-score" style={{
                  color: "#2563eb",
                  fontWeight: "bolder",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}>₹{scorer.earnings}</span>
              </Row>
            </div>
        ))}
      </Col>
    );
  };

  const mostPlayedGames = () => {
    return (
      <Col className="trending-card most-played-games ml-auto mr-auto" xs="12" md="6">
        <h4 className="sub-title">
          <i className="now-ui-icons ui-1_send"></i>
          Most Played Games
        </h4>
        {"top_games" in trendingData && trendingData["top_games"].map((game, index) => (
            <div className="scorer-list" style={{display:"flex", flexDirection:"column", gap:"16px", width:"100%", padding:"0",margin:"auto", marginBottom:"10px"}}>
              <Row className="scorer-item" style={{height:"50px",backgroundColor:"#ffffff", padding:"16px",borderRadius:"8px", display:"flex", justifyContent:"space-between", alignItems:"center", transition:"background-color 0.3s ease, transform 0.2s ease", boxShadow:"0 4px 10px rgba(0, 0, 0, 0.05)"}}>
                <span className="scorer-name" style={{
                  fontWeight: "bold",
                  color: "#1e293b",
                  width: "50%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}>
                              <span className="icon-fire" style={{
                                width: "18px",
                                height: "18px",
                                backgroundSize: "contain"
                              }}></span> {game.name}
                            </span>
                <span className="scorer-score" style={{
                  color: "#2563eb",
                  fontWeight: "bolder",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}>{game.interested} Players</span>
              </Row>
            </div>
        ))}
      </Col>
    );
  };

  const mostPopularArtists = () => {
    return (
      <Col className="trending-card top-scorers ml-auto mr-auto" xs="12" md="6">
        <h4 className="sub-title">
          <i className="now-ui-icons ui-2_favourite-28"></i>
          Most Popular Creators
        </h4>

        {"top_artists" in trendingData && trendingData["top_artists"].map((artist, index) => (
            <div className="scorer-list" style={{display:"flex", flexDirection:"column", gap:"16px", width:"100%", padding:"0",margin:"auto", marginBottom:"10px"}}>
              <div className="scorer-item" style={{height:"50px",backgroundColor:"#ffffff", padding:"16px",borderRadius:"8px", display:"flex", justifyContent:"space-between", alignItems:"center", transition:"background-color 0.3s ease, transform 0.2s ease", boxShadow:"0 4px 10px rgba(0, 0, 0, 0.05)"}}>
                <span className="scorer-name" style={{
                  fontWeight: "bold",
                  color: "#1e293b",
                  width: "50%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "0.7rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}>
                              <span className="icon-fire" style={{
                                width: "18px",
                                height: "18px",
                                backgroundSize: "contain"
                              }}></span> {artist.name}
                            </span>
                <span className="scorer-score" style={{
                  color: "#2563eb",
                  fontWeight: "bolder",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px"
                }}>Sel by {artist.artist}</span>
              </div>
            </div>
        ))}
      </Col>
    );
  };


  const promotions = [
    { id: 1, color: 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)', icon: '💰', text: 'Sign-Up Bonus', value: '₹75' },
    { id: 2, color: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)', icon: '🎁', text: 'Referral Reward', value: '₹50 per referral' },
    { id: 3, color: 'linear-gradient(135deg, #f093fb 0%, #f5576c 100%)', icon: '🏆', text: 'Daily Streaks', value: '₹100 every week' },
    { id: 4, color: 'linear-gradient(135deg, #c2e9fb 0%, #81a4fd 100%)', icon: '🎉', text: 'Coins', value: 'Unlimited' }
  ];

  const handlePromotionClicked = (e, id) => {
    e.preventDefault();
    if (id === 1) {
      navigate('/signup');
    } else if (id === 2) {
      navigate('/profile', { state: { 'activeTab': 1 } });
    } else if (id === 3) {
      navigate('/profile', { state: { 'activeTab': 0 } });
    } else if (id == 4) {
      navigate('/coins');
    }
  }

  const topGamesSection = () => {
    return (
        <div className="section section-basic">
          <Container style={{padding: "0px"}}>
{/*            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" >
                Trending
                <i className='fas fa-fire-alt' style={{fontSize: "30px", color: "red"}}></i>
              </h2>
            </Col>*/}
            <>
              <Swiper
                  initialSlide="1"
                  effect={'cards'}
                  grabCursor={true}
                  modules={[EffectCards]}
              className="mySwiper"

          >
            <SwiperSlide>{topScorers()}</SwiperSlide>
            <SwiperSlide>{mostPlayedGames()}</SwiperSlide>
            <SwiperSlide>{mostPopularArtists()}</SwiperSlide>
          </Swiper>
        </>
        </Container>
        </div>
    );
  };

  const stepsToPlay = () => {
    return <div className="section section-basic" id="basic-elements" style={{    color: "black",padding: "5px" }}>
      <Container>
    <Row>
    <Col className="ml-auto mr-auto text-center">

      <h2 className="title">
        It's super easy to start playing on <span className="text-logo">Con10Craze</span>
      </h2>
      <Row>
        <div style={containerStyles}>
          <div style={containerStyles}>
            <Col className="ml-auto mr-auto" xs="12" md="4">
              <div className="free-text-content" key="1" style={divStyles}>
                <div style={numberCircleStyles}>1</div>
                <Col style={{marginLeft: "10px"}}>
                  <Row style={{color: "black", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>
                    <h2 className="title">Select a Game</h2>
                  </Row>
                  <Row style={{textAlign: "left"}}>
                    <p>Choose upcoming game that you want to play</p>
                  </Row>
                </Col>
              </div>
            </Col>

            <Col className="ml-auto mr-auto" xs="12" md="4">
              <div className="free-text-content" key="2" style={divStyles}>
                <div style={numberCircleStyles}>2</div>
                <Col style={{marginLeft: "10px"}}>
                  <Row style={{color: "black", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>
                    <h2 className="title">Create your Team</h2>
                  </Row>
                  <Row style={{textAlign: "left"}}>
                    <p>Use your skills to pick the right creators</p>
                  </Row>
                </Col>
              </div>
            </Col>

            <Col className="ml-auto mr-auto" xs="12" md="4">
              <div className="free-text-content" key="3" style={divStyles}>
                <div style={numberCircleStyles}>3</div>
                <Col style={{marginLeft: "10px"}}>
                  <Row style={{color: "black", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>
                    <h2 className="title">Participate</h2>
                  </Row>
                  <Row style={{textAlign: "left"}}>
                    <p>Lock in your team and win cash rewards</p>
                  </Row>
                </Col>
              </div>
            </Col>

          </div>
        </div>
      </Row>
    </Col>
    </Row>
    </Container>
    </div>
  }

  const upcomingGameSection = () => {
    return <div className="section section-basic" id="basic-elements" style={{padding: "5px"}}>
      {gamesLoaded && <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center">
            <h2 style={{
              position: 'relative',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
              margin: '20px 0'
            }}>
                  <span style={{
                    display: 'inline-block',
                    position: 'relative',
                    padding: '0 20px',  // Added extra padding for more spacing
                  }}>
                    UPCOMING GAME
                  </span>
              <span style={{
                position: 'absolute',
                top: '50%',
                left: '0',
                width: '25%',
                height: '2px',
                background: 'linear-gradient(to left, black 20%, transparent 100%)'
              }}></span>
              <span style={{
                position: 'absolute',
                top: '50%',
                right: '0',
                width: '25%',
                height: '2px',
                background: 'linear-gradient(to right, black 20%, transparent 100%)'
              }}></span>
            </h2>

            {latestGame()}
            <button style={{
              backgroundColor: '#320544',
              color: '#fff',
              marginTop: "2px",
              marginBottom: "2px",
              border: 'none',
              padding: '10px 30px',
              fontWeight: 'bold',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              display: 'inline-block',
              height: '55px',
              width: '180px',
              marginTop: "10px",
              // backgroundImage: ' linear-gradient(to right, rgb(244, 138, 144), rgb(229, 09, 20)'
            }}
                    onClick={(e) => handleAllGames(e)}
            >
              MORE GAMES
            </button>


          </Col>
        </Row>
      </Container>
      }
    </div>
  }
  const introSection = () => {
    return <div className="section section-basic" id="basic-elements" style={{padding: "5px"}}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8" style={{padding: "5px"}}>

            <h2 className="title">
              Con10Craze Fantasy Sports
            </h2>
            <p>
              Join Con10Craze, the first Social Fantasy Sports platform in India! Pick Instagram creators as your
              players, and their engagement rates decide your score. Climb the leaderboard and win big by backing the
              most engaging influencers!
            </p>

          </Col></Row>
      </Container>
    </div>
  }

  const artistClicked = (e, artistSocialMediaHandle) => {
    e.preventDefault();
    navigate('/creator/' + artistSocialMediaHandle);
  }

  const creatorSection = () => {
    return <div className="section section-basic" id="basic-elements" style={{padding: "0px",}}>
      <Container>

        <Row>
          <Col className="ml-auto mr-auto text-center" md="8" style={{paddingLeft: "10px"}}>
            {artists.length > 0 && <h2 className="title" >
              Meet Our Creators
            </h2>}
          </Col>

          <div className="scrollmenu" style={{padding: "0px"}}>
            {artists.map((artist, index) => {
              const artist_engagement = artist.avg_followers > 0 ? (artist.avg_likes + 2 * artist.avg_comments) / (artist.avg_followers) * 100 : 0;
              return (
                  <div onClick={(e) => artistClicked(e, artist.social_media_handle)} style={{
                    width:"200px",  // Increased width to better accommodate the content
                    margin:"15px",  // Increased margin for spacing between elements
                    textAlign: "center",
                  }}>
                    <img className="rounded-circle artist-img"
                         alt="Con10Craze Fantasy Creators, Artists, Influencers, Celebrities"
                         src={artist["profile_img"]}
                         style={{
                           height:"50%",
                           margin: 0, // Ensures the image doesn't stretch or distort
                         }}
                    />
                    <a className="artist-name"
                       href={"https://instagram.com/" + artist.social_media_handle}
                       target="_blank" rel="noopener noreferrer"
                       style={{
                         display: "flex",
                         fontSize: "initial",
                         justifyContent: "center",
                         textAlign: "center",
                         background: `linear-gradient(45deg, #405de6, #5851db, #833AB4, #c13584, #E1306c, #fd1d1d)`,
                         backgroundClip: "text",
                         color: "transparent"
                       }}>
                      {artist.name}</a>
                    <p style={{fontWeight: "bold", fontSize: "initial", marginBottom: "0px", color: "black"}}>
                      {formatter.format(artist.avg_followers)} Followers
                    </p>
                    <p style={{color: "green", fontWeight: "bold"}}>
                      <i className="now-ui-icons media-2_sound-wave" style={{marginRight: "0.25rem"}}></i>
                      {artist_engagement.toFixed(2)}
                    </p>
                  </div>

              )
            })}
          </div>

        </Row>

        {!userService.isLoggedIn() && <Row>
          <Col className="text-center ml-auto mr-auto" lg="6" md="8" style={{padding: "5px"}}>
            <Button
                className="btn-round"
                color="info"
                style={{fontWeight: "bold"}}
                onClick={(e) => {
                  e.preventDefault();
                  pixelCreatorRegisterConversionTracking();
                  navigate('/signup?artist=true');
                }}
                size="lg"
            >
              Are you a Creator?
            </Button>
          </Col>
        </Row> }

      </Container>
    </div>
  }

  const scoringSection = () => {
    return <div>
      <Container>
        <Row>
          <Col className="text-center ml-auto mr-auto" style={{padding: "15px"}}>
            <ScoreInformation/>
          </Col>
        </Row>
      </Container>
    </div>
  }

  const faqSection = () => {
    return <div className="section section-basic" style={{padding: "5px"}}>
      <Container>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h3 className="title">
            Frequently Asked Questions
          </h3>
        </Col>
        <Accordion/>

      </Container>
    </div>

  }

  const handleParticipate = (e, game) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/game/' + game["uuid"]);
  }

  const handleAllGames = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/fantasy-games/');
  }

  const MyTimer = ({ expiryTimestamp }) => {
    const {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({
      expiryTimestamp,
      autoStart: true,  // Ensure the timer starts automatically
    });

    return (
        <div>
      <span style={{ fontStyle: "italic", fontSize: "small" }}>
        Starts in:&nbsp;
        {days > 0 ? days + "d " : ""}
        {hours > 0 ? hours + "h " : ""}
        {minutes}m {seconds}s {/* Added seconds for a real-time update */}
      </span>
        </div>
    );
  };

  const latestGame = () => {
    const game = upcoming
    const spots = game.max_users > 0 ? game.max_users : 1000;
    const remSpots = game.interested > 0 ? spots - game.interested : spots;
    let userParticipated = "user_participated" in game && game["user_participated"] === true;
    let prizeDistribution = Math.floor(game.max_users * game.price * game.winners_users_percent / 100);
    return <Container>

          <Card className="card-signup"
                style={{
                  boxShadow: "0 4px 6px -4px rgba(0, 0, 0, 0.1)", border: "2px solid #dddddd",
                  borderRadius: "20px"
                }}
                onClick={(e) => handleParticipate(e, game)}>
            {game.parent_name &&
                <h3 style={{
                  marginTop: "5px",

                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  margin: "5px",
                  fontWeight: "bold",
                  backgroundColor: "#320544", color: "white"
                }}>{game.parent_name}</h3>}
            <CardBody style={{padding: "5px"}}>
              <Col>
                <Row>
                  <Col className="ml-auto mr-auto">
                    <h5 style={{
                      fontWeight: "bold",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}>{game.name}</h5>
                    <MyTimer
                        expiryTimestamp={new Date(game.start_time)}></MyTimer>
                    <i className="now-ui-icons sport_trophy pull-left" style={{
                      fontWeight: "bolder",
                      marginTop: "20px",
                      marginRight: "0.5rem",
                      color: "#FFD700"
                    }}></i>
                    <Row>
                          <span className='pull-left' style={{
                            color: "#B00610",
                            fontWeight: "bold",
                            marginTop: "15px"
                          }}>*₹{prizeDistribution.toLocaleString()}</span>
                    </Row>
                  </Col>
                </Row>
                <div className="progress">
                  <div className="progress-bar-animated progress-bar progress-bar-striped  bg-success"
                       role="progressbar"
                       aria-valuenow={spots - remSpots} // Use the variable here
                       aria-valuemin="0"
                       aria-valuemax={spots}
                       style={{
                         animation: "progress-bar-stripes 0.3s linear infinite",
                         width: `${(spots - remSpots) / spots * 100}%`
                       }}></div>
                </div>
                <Row>

                  <Col className="ml-auto mr-auto">
                    <div className="pull-left">
                      {remSpots} spots left
                    </div>
                    <div className="pull-right">
                      {spots} spots
                    </div>
                  </Col>
                </Row>

                <hr style={{height: "5px"}}/>

                <Row style={{fontWeight: "500"}}>

                  <Col className="ml-auto mr-auto" xs="4" style={{
                    color: "green",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold"
                  }}>
                    <span>Fee: ₹{game.price}</span>
                  </Col>

                  <Col className="ml-auto mr-auto" xs="8">
                    {userParticipated &&
                        <Button color="info" style={{width: "100%", borderRadius: "100px"}}
                                onClick={(e) => handleParticipate(e, game)}>MONITOR YOUR
                          TEAM
                        </Button>
                    }
                    {!userParticipated &&
                        <Button color="info" style={{width: "100%", borderRadius: "100px"}}
                                onClick={(e) => handleParticipate(e, game)}>PLAY NOW
                        </Button>
                    }
                  </Col>

                </Row>

              </Col>
            </CardBody>
          </Card>
    </Container>
  }



  const handleChallengeClick = () => {
    // Logic to open Instagram friends sync or challenge page
    console.log('Challenge Your Friends clicked');
  }

  const handleCoinsClick = (e) => {
    e.preventDefault();
    navigate('/coins');
  }

  const coins = () => {
    return (
      <div className="section section-basic" id="basic-elements" style={{padding: "5px"}}
      onClick={(e) => handleCoinsClick(e)}>
      <Container>

        <Row style={containerStylesPoint}>
          <Col className="ml-auto mr-auto text-center">
            <h3 className="title" style={{ textDecoration: "underline" }}>
                Start Earning Coins!
            </h3>
            <p>Coins are your gateway to exclusive rewards and perks on Con10Craze. Don't miss out—start collecting coins today and elevate your game!</p>
          </Col>
        </Row>
      </Container>
      </div>
    )
  }

  return (
      <div>
        <SEO
            title="Con10Craze Fantasy Sports Games: Create Dream Teams, Win Real Money, Earn up to INR 1 Lakh referral bonus"
            description="Join Con10Craze's Fantasy Sports Games and craft dream teams of creators and influencers. Earn real money by achieving the best engagement scores. Let the games begin!"
            type="website"
            url="https://www.con10craze.com/"/>


        {/* NON LOGGED-IN USER */}
        {!userService.isLoggedIn() && (
            <>
              {introSection()}
              {freePlay()}
              {stepsToPlay()}
              <Counters gamesStats={gamesStats} />
              {topGamesSection()}
              {howToSection()}
              {scoringSection()}
              {creatorSection()}
              {testimonials()}
              {faqSection()}
            </>
        )}

        {userService.isLoggedIn()  &&(
            <>
              {upcomingGameSection()}
              <Counters gamesStats={gamesStats} />
              {topGamesSection()}
              {/*{opinion()}*/}
              {creatorSection()}
              {/*{explore()}*/}
              {testimonials()}
            </>
        )}
      </div>
  );
}

export default LandingPage;

